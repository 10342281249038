<template>
  <div>
    <v-col class="mb-15 pb-10 col-12">
      <v-card>
        <v-col
          cols="12"
          class="pl-0 pr-0 pb-1"
        >
          <v-row>
            <v-spacer />

            <v-col
              cols="3"
              sm="2"
              md="2"
              class="pr-3"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="startDate"
                    label="Tarih"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="3"
              sm="2"
              md="2"
              class="pr-3"
            >
              <v-select
                v-model="santralModel"
                :items="santralDataList"
                label="Santral"
              />
            </v-col>

            <v-col
              cols="3"
              sm="1"
              md="1"
              class="pr-6"
            >
              <v-btn
                color="primary"
                @click="setData"
              >
                Filtrele
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <div
            v-for="string in stringboxNameList[santralModel]"
            :key="string.value + santralModel"
          >
            <div style="border: 1px solid black">
              <div class="text-center col-12 ml-2 mt-0 pb-0 pt-0">
                <b> {{ string.text }}</b>
              </div>
              <div
                :id="'d3jsdccurrent' + string.value + santralModel"
                class="svgclass"
              />
            </div>
          </div>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import * as Heatmap from '@/api/CombinerBox/Heatmap';
import * as d3 from 'd3';
import moment from 'moment';
import helper from '@/api/helper';
import {
  d3jsChartComponent,
  d3jsChartToolTip,
} from '@/api/Components/d3jsComponent';

export default {
  data() {
    return {
      measureList: [],
      santral_items: [],
      startDate: moment().format('YYYY-MM-DD'),
      finishDate: moment().format('YYYY-MM-DD'),
      menu: false,
      modal: false,
      menu2: false,
      measures: [],
      measureSelect: 'DCCurrent',
      componentKey: 1,
      title: '',
      santralModel: '',
      inverterList: [],
      height: '',
      HeatmapData: [],
      stringboxList: {},
      stringboxNameList: {},
      stepTime: 5,
      dcCurrent: {},
    };
  },
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },
  },
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;

    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const { santralStringboxData } = self.$store.getters;

      if (
        santralStringboxData !== undefined
        && Object.keys(santralStringboxData).length > 0
      ) {
        clearInterval(myVar1);
        self.santralModel = santral;
        const dcCurrent = {};

        const stringboxNameList = {};
        const stringboxList = {};

        Object.keys(santralStringboxData).forEach((s) => {
          // eslint-disable-next-line no-plusplus
          stringboxList[s] = Object.keys(santralStringboxData[s]);

          stringboxNameList[s] = [];
          Object.keys(santralStringboxData[s]).forEach((i) => {
            const stringbox = santralStringboxData[s][i].STRINGBOX;
            dcCurrent[stringbox] = [];
            stringboxNameList[s].push({
              value: stringbox,
              text: santralStringboxData[s][i].STRINGBOXNAME,
            });

            const gMeasure = santralStringboxData[s][i].general_measure_list;
            Object.keys(gMeasure).forEach((k) => {
              if (
                gMeasure[k].value.indexOf('DCCurrent') > -1
                && gMeasure[k].value !== 'DCCurrent'
              ) {
                dcCurrent[stringbox].push(gMeasure[k].text);
              }
            });
          });
        });
        self.stringboxList = stringboxList;
        self.stringboxNameList = stringboxNameList;
        self.dcCurrent = dcCurrent;

        self.setData();
      }
    }
  },
  methods: {
    setData() {
      this.stepTime = 5;
      const stepTime = this.stepTime * 2;
      const MinuteGrup = [];
      for (let mm = this.stepTime; mm < 60; mm += stepTime) {
        MinuteGrup.push(mm);
      }

      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.startDate,
          stringbox: this.stringboxList[this.santralModel],
          maxcount: 3000,
          MinuteGrup,
        },
      };

      const temp = Heatmap.default.heatmapDailyMeans_string_diff(
        formdata,
        this.dcCurrent,
      );

      temp.then((data) => {
        Object.keys(data).forEach((item) => {
          this.heatmaps(data[item], item);
        });
      });
    },
    heatmaps(data, string) {
      const { santralModel } = this;
      const chartId = `d3jsdccurrent${string}${santralModel}`;
      const elemnet = document.getElementById(chartId);

      if (elemnet !== null) {
        elemnet.innerHTML = '';
      }

      let itemlenght = 5;
      if (this.stringboxList[this.santralModel].length > 0) {
        itemlenght = this.stringboxList[this.santralModel].length;
      }
      const margin = {
        top: 0,
        right: 10,
        bottom: 50,
        left: 90,
      };

      const windowsWidth = document.documentElement.clientWidth;
      const extraWidth = 330;
      const unit = 'A';

      const width = windowsWidth - extraWidth - margin.left - margin.right;
      const height = itemlenght * 28 + 20;
      const parameter = {
        width,
        height,
        transform: `translate(${margin.left},${margin.top})`,
        chartId,
        chartXkey: 'time',
        chartYkey: 'dc_current',
        measureSelect: 'dc_current',
        unit,
        startDate: this.startDate,
      };
      // eslint-disable-next-line no-underscore-dangle
      const _t = d3jsChartToolTip(parameter);
      const mousemove = function (d) {
        const value = helper.show_float_val(d.value);
        let mean = 0;
        if (!helper.is_Empty(d.mean)) {
          mean = d.mean.toFixed(2);
        }

        _t.toolTip
          .html(
            `${parameter.startDate} ${d[parameter.chartXkey]} <br> ${
              d[parameter.chartYkey]
            }<br> ${parameter.measureSelect} : ${value} ${
              parameter.unit
            } <br> Ortalama : ${mean} ${parameter.unit}`,
          )
          .style('z-index', '9999')
          .style('left', `${d3.mouse(this)[0] - 120}px`);
      };
      d3jsChartComponent(parameter, data, mousemove, _t.toolTip);
    },
  },
};
</script>

<style scoped>
.svgclass {
  overflow-x: auto;
  width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
